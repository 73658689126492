import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import CardContainer from '../components/CardContainer/CardContainer';

const fakeBoats = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const slugPart = {
  en: 'boat-rent-at',
  en_us: 'boat-rent-at',
  es: 'alquiler-barcos-en',
  de: 'yachtcharter',
  fr: 'location-de',
};
const slugPart1 = {
  en: 'owner',
  en_us: 'owner',
  es: 'armador',
  de: 'besitzer',
  fr: 'propriétaire',
};
const slugPart2 = {
  en: 'boats-of-',
  en_us: 'boats-of-',
  es: 'barcos-de-',
  de: 'schiffe-von-',
  fr: 'navires-de-',
};

const convertObject = (original) => ({
  cardTitle: `${original.name} - ${original.model}`,
  passengersNumber: original.capacity,
  lengthNumber: original.length,
  bathroomsNumber: original.toilets,
  year: original.build_year,
  cabinsNumber: original.cabins,
  withSkipper: original.skipper,
  currencyPrice: '0',
  priceNumber: '0',
});

const Boats = ({
  owner, boats, api, mobile, lang, places, back
}) => {
  const [boatList, setBoatList] = useState([]);

  useEffect(() => {
    // api.get('boats', '/boats', {}).then(response => {
    //     const prepared=response.map(el=>convertObject(el));
    //     setBoatList(prepared);
    //   }).catch(error => {
    //       ////console.log('Error:', error);
    //     setBoatList([]);
    //   });
  }, []);

  return (
    <>
      {mobile ? (
        <>
          {/* <Link to={encodeURI(`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}`)}> */}
          <div onClick={()=>{back('main')}}>
            <div id="2" className="OwnerRow OwnerSticky" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
              <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                <div className="OwnerRightArrow" style={{ transform: 'rotate(180deg)' }} />
                <div style={{ marginLeft: '8px' }}>BARCOS</div>
              </div>
            </div>
          </div>
          {/* </Link> */}
          <h1 id="boats" name="boats" className="OwnerH1" style={{ marginTop: '16px' }}>
Barcos de
            {' '}
            {owner.name}
          </h1>

        </>
      ) : <div />}
      
      <div className="OwnerBoatsContainer">
        {
                (boats.length === 0) ? fakeBoats.map((el) => (
                  <Skeleton active>
                    <h1>Test</h1>
                    <div style={{ width: '100%', height: '250px' }} />
                  </Skeleton>
                ))
                  // : boats.map((el) => <Link to={`${lang}/${slugPart[lang]}/${el.slug[lang]}/${el.id}`}><Card key={JSON.stringify(el)} cardInfo={convertObject(el)} /></Link>)
                  : <CardContainer lang={lang} places={places} slugPartBoatRent={slugPart} lang={lang} justifyContentClass="CardContainerContainerJustifyContentClass" cards={boats} />
            }
      </div>
    </>
  );
};

export default Boats;
