import { Card } from 'boatjump-react-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './cardContainer.css';
import * as _ from 'lodash';

const CardContainer = (props) => {
  const { cards = [], places, lang } = props;
  const placeIds = _.groupBy(places, 'id');
  const prevFunc = (e, youtubeVideo) => {
    if (e.nativeEvent.path[0].className === 'YoutubeIconContainer') {
      event.preventDefault();
      event.stopPropagation();
      window.location.href = youtubeVideo;
      return false;
    }
  };

  return (
    <div className={`CardContainerContainer ${props.justifyContentClass}`}>
      {
                cards.map((cardProps, i) => {
                  const ancestryArray = placeIds[cardProps.main_place_id];
                  const placeIdsFiltered = ancestryArray && ancestryArray.length > 0 ? ancestryArray[0].ancestry.split('/') : [];
                  const placeNames = placeIdsFiltered.map((place) => placeIds[place][0].name[lang]);
                  const place = placeNames.reduce((previousPlace, place, i) => (i === 0 ? place : `${previousPlace} · ${place}`));
                  const cardInfo = {
                    cardTitle: cardProps.model,
                    place,
                    passengersNumber: cardProps.capacity,
                    lengthNumber: cardProps.length,
                    bathroomsNumber: cardProps.toilets,
                    year: cardProps.build_year,
                    cabinsNumber: cardProps.cabins,
                    withSkipper: cardProps.skipper,
                    currencyPrice: cardProps.currency,
                    priceNumber: '5,430',
                  };
                  const cardText = {
                    badgeText: 'Disponible',
                    passengers: 'Pasajeros',
                    length: 'm de eslora',
                    bathroom: 'baño',
                    year: 'Año',
                    cabin: 'Cabina',
                    skipper: 'Patrón',
                    price: 'Precio',
                    extras: 'con extras obligatorios',
                  };
                  const images = [];
                  let youtubeVideo = '';
                  if (cardProps.boat_assets_image_video && cardProps.boat_assets_image_video.length > 0) {
                    cardProps.boat_assets_image_video.map((image) => {
                      if (image.url.includes('youtube')) {
                        youtubeVideo = image.url;
                      } else {
                        images.push(`http://assets.boatjump.com/uploads/boat/${cardProps.id}/photos/${image.url}`);
                      }
                    });
                  } else {
                    images.push('https://boatjumpproduction.s3.eu-central-1.amazonaws.com/static/images/logo-boatjump-thumb.png');
                  }
                  youtubeVideo = youtubeVideo === '' ? 'https://www.youtube.com/channel/UCsu4t0_lwwzF5uvRBdOvHQQ' : youtubeVideo;

                  return (
                    <div key={i} style={{ width: '100%', maxWidth: '266px' }} onMouseDown={(e) => prevFunc(e, youtubeVideo)}>
                      <Link to={`/${props.lang}/${props.slugPartBoatRent[props.lang]}/${cardProps.slug[props.lang]}/${cardProps.id}/`} style={{ width: '266px' }}>
                        <Card
                          images={images}
                          youtubeVideo={youtubeVideo}
                          badgeSize={cardProps.badgeSize}
                          badgeAvailability={cardProps.badgeAvailability}
                          cardInfo={cardInfo}
                          cardText={cardText}
                        />
                      </Link>
                    </div>
                  );
                })
            }
    </div>
  );
};

CardContainer.propTypes = {
  cards: PropTypes.array,
  justifyContentClass: PropTypes.string,
};

CardContainer.defaultProps = {
  cards: [],
  justifyContentClass: '',
};

export default CardContainer;
