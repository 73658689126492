import { Location, Router } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Header from '../components/Header/Header';
import '../components/layout.css';
import SEO from '../components/seo';
import '../static/Owner.css';
import Boats from '../subpages/Boats';
import Reviews from '../subpages/Reviews';

const fakeBoats = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const commentaries = [1, 2, 3, 4, 5];
const slugPart1 = {
  en: 'owner',
  en_us: 'owner',
  es: 'armador',
  de: 'besitzer',
  fr: 'propriétaire',
};
const slugPart2 = {
  en: 'boats-of-',
  en_us: 'boats-of-',
  es: 'barcos-de-',
  de: 'schiffe-von-',
  fr: 'navires-de-',
};


const Owner = (props) => {
  const [t, i18n] = useTranslation();
  const {
    owner, boats, boatExtras, boatFeatures, reviews, description, url, lang, special, places
  } = props.pageContext;
  const [windowWidth, setWindowWidth] = useState(0);
  const [headerBackground, setHeaderBackground] = useState('');
  const [currentPage, setCurrentPage] = useState('main');
  // console.log('url', `/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}`);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.screen.width);
      window.onresize = (e) => {
        setWindowWidth(window.screen.width);
      };
    }
  }, []);

  const desktopLayout = (
    <div className="OwnerLayout">
      <div className="OwnerContent OwnerContentLeft OwnerContentMargin">
        <Breadcrumb breadcrumb={[{url: "/", text: "Home"}, {url: "", text: owner.name}]}></Breadcrumb>
        <h1 id="boats" name="boats" className="OwnerH1">
          {t('Barcos de')}
          {' '}
          {owner.name}
        </h1>
        <div className="OwnerBoatsContainer">
          {/* <AWSBoat owner={owner} boats={boats} path="/Owner/1/boats" mobile={false} /> */}
          <Boats places={JSON.parse(places)} owner={owner} boats={boats} lang={lang} path={`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}/boats`} mobile={false} />
        </div>
        <h1 id="reviews" name="reviews" className="OwnerH1" style={{ marginTop: '32px' }}>
          {t('search.ratings.owner_ratings')}
          {' '}
          {owner.name}
        </h1>
        <Reviews owner={owner} reviews={reviews} path={`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}/reviews`} mobile={false} />
      </div>
      <div className="OwnerContent OwnerContentRight">
        <div className="OwnerContentUser">
          <div className="OwnerContentIcon" style={{ background: 'url(http://3.bp.blogspot.com/-MLP0L9Cll3o/URWfu2TZ_KI/AAAAAAAAASw/Pxrk3uP-RLA/s1600/Toothless-Old-Man.jpg)no-repeat center', backgroundSize: 'cover' }} />
          <div style={{
            width: 'calc(100% - 144px)', height: '128px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
          }}
          >
            <div className="OwnerContentText" title={owner.company_name}>
              {owner.name}
            </div>
            <div className="OwnerContentSecondaryText" title={owner.company_name}>
              {owner.country}
            </div>
          </div>
        </div>
        <div className="OwnerSeparator" />
        <div className="OwnerResumeContainer">
          <div className="OwnerResumeTitle">{t('provider.info')}</div>
          <div style={{ width: '100%', padding: '16px' }}>
            <div className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
              <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                <div style={{ marginLeft: '8px' }}>{t('provider.average_rating')}</div>
              </div>
              <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                <span className="OwnerNumbers OwnerEnfatized">{owner.average_rating}</span>
                <span className="OwnerNumbers" style={{ marginRight: '8px' }}>/5</span>
                <div className="OwnerStar" />
              </div>
            </div>
            <a href="#reviews">
              <div className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <div className="OwnerRightArrow" />
                  <div style={{ marginLeft: '8px' }}>Reviews</div>
                </div>
                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <span className="OwnerNumbers OwnerEnfatized" style={{ marginRight: '8px' }}>{owner.reviews_count}</span>
                  <div className="OwnerReviews" />
                </div>
              </div>
            </a>
            <a href="#boats">
              <div className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>

                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <div className="OwnerRightArrow" />
                  <div style={{ marginLeft: '8px' }}>{t('provider.boats')}</div>
                </div>
                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <span className="OwnerNumbers OwnerEnfatized" style={{ marginRight: '8px' }}>{owner.boats_count}</span>
                  <div className="OwnerBoats" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  const MobileNav = ({ owner }) => (
    <div className="OwnerResumeContainer">
      <div className="OwnerResumeTitle">{t('provider.info')}</div>
      <div style={{ width: '100%', padding: '16px' }}>
        <div onClick={()=>{setCurrentPage('reviews')}}>
          <div id="1" className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
            <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
              <div className="OwnerRightArrow" />
              <div style={{ marginLeft: '8px' }}>Reviews</div>
            </div>
            <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
              <span className="OwnerNumbers OwnerEnfatized" style={{ marginRight: '8px' }}>{owner.reviews_count}</span>
              <div className="OwnerReviews" />
            </div>
          </div>
        </div>
        <div onClick={()=>{setCurrentPage('boats')}}>
          <div id="2" className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>

            <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
              <div className="OwnerRightArrow" />
              <div style={{ marginLeft: '8px' }}>{t('provider.boats')}</div>
            </div>
            <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
              <span className="OwnerNumbers OwnerEnfatized" style={{ marginRight: '8px' }}>{owner.boats_count}</span>
              <div className="OwnerBoats" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const FadeTransitionRouter = (props) => (
    <Location>
      {({ location }) => (
        <TransitionGroup className="transition-group">
          <CSSTransition key={location.key} classNames="fade" timeout={500}>
            <Router location={location} className="router">
              {props.children}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Location>
  );

  const MobileLayout = ({ owner }) => (
    <div className="OwnerLayout">
      <div className="OwnerContentUser" style={{ marginTop: '32px' }}>
        <div className="OwnerContentIcon" style={{ background: 'url(http://3.bp.blogspot.com/-MLP0L9Cll3o/URWfu2TZ_KI/AAAAAAAAASw/Pxrk3uP-RLA/s1600/Toothless-Old-Man.jpg)no-repeat center', backgroundSize: 'cover' }} />
        <div style={{
          width: 'calc(100% - 144px)', height: '128px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
        >
          <div className="OwnerContentText" title={owner.name}>
            {owner.name}
          </div>
          <div className="OwnerContentSecondaryText" title={owner.name}>
            {owner.average_rating ? (
              <div className="OwnerRow" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <div style={{ marginLeft: '8px' }}>Valoración media</div>
                </div>
                <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
                  <span className="OwnerNumbers OwnerEnfatized">{owner.average_rating}</span>
                  <span className="OwnerNumbers" style={{ marginRight: '8px' }}>/5</span>
                  <div className="OwnerStar" />
                </div>
              </div>
            ) : <div style={{ fontSize: '18px' }}>{t('provider.no_reviews')}</div>}
          </div>
        </div>
      </div>
      <div className="OwnerSeparator" />
      <div>
        {
          (currentPage === 'main') ? <MobileNav owner={owner} path={encodeURI(`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}/`)} /> :
          (currentPage === 'boats') ? <Boats owner={owner} boats={boats} lang={lang} path={encodeURI(`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}/boats/`)} mobile back={setCurrentPage}/>:
          (currentPage === 'reviews') ? <Reviews owner={owner} reviews={reviews} lang={lang} path={encodeURI(`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}/reviews/`)} mobile back={setCurrentPage}/>:
          ''
        }
      </div>
      <Breadcrumb breadcrumb={[{url: "/", text: "Home"}, {url: "", text: owner.name}]} ></Breadcrumb>
    </div>
  );

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <SEO title={`Provider ${owner.name}`} description={description} url={url} lang={lang} />
      <Header scrollclass="HeaderContainerBoatDetailScrollMobile" page="boatDetail"  special={special}/>
      {
                (windowWidth >= 768) ? desktopLayout : <MobileLayout owner={owner} />
            }
    </div>
  );
};

Owner.propTypes = {
  pageContext: PropTypes.shape({
    owner: PropTypes.object,
  }),
};
Owner.defaultProps = {

};

export default Owner;
