import { Commentary } from 'boatjump-react-components';
import moment from 'moment';
import React from 'react';

const commentaries = [1, 2, 3, 4, 5];

const slugPart1 = {
  en: 'owner',
  en_us: 'owner',
  es: 'armador',
  de: 'besitzer',
  fr: 'propriétaire',
};
const slugPart2 = {
  en: 'boats-of-',
  en_us: 'boats-of-',
  es: 'barcos-de-',
  de: 'schiffe-von-',
  fr: 'navires-de-',
};

const Reviews = ({ owner, reviews, mobile, lang, back }) => (
  <>
    {mobile ? (
      <>
        {/* <Link to={encodeURI(`/${lang}/${slugPart1[lang]}/${slugPart2[lang]}${owner.name}-${owner.id}`)}> */}
        <div onClick={()=>{back('main')}}>
          <div id="1" className="OwnerRow OwnerSticky" style={{ height: '35px', borderBottom: '1px solid #ccc' }}>
            <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
              <div className="OwnerRightArrow" style={{ transform: 'rotate(180deg)' }} />
              <div style={{ marginLeft: '8px' }}>REVIEWS</div>
            </div>
          </div>
        </div>  
        {/* </Link> */}
        <h1 id="reviews" name="reviews" className="OwnerH1" style={{ marginTop: '32px' }}>
Valoraciones de
          {' '}
          {owner.name}
        </h1>

      </>
    ) : <div />}
    {reviews.map((el) => <div key={JSON.stringify(el)} style={{ marginTop: '32px' }}><Commentary commentary={el.comment.es} rate={el.rating} date={moment(el.created_at).format('DD/MM/YYYY')} /></div>) }
  </>
);

export default Reviews;
